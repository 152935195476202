export default class FormException extends Error {
    public formErrors: IFormErrors;

    constructor(message?: string, formErrors?: IFormErrors) {
        super(message);
        this.formErrors = formErrors ?? {};
    }
}

interface IFormErrors {
    [key: string]: string;
}
