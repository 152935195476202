<script setup lang="ts">
  interface Event {
    title: string,
    description: string,
    url: string,
    coordinates: [number, number],
  }

  import { onBeforeUnmount, onMounted, ref } from 'vue';
  // const mapboxgl = () => import('mapbox-gl');
  import mapboxgl, { type Map, Marker, Popup, LngLat } from 'mapbox-gl';
  import 'mapbox-gl/dist/mapbox-gl.css';

  const { events } = defineProps<{events: Event[]}>();

  mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

  const mapContainer = ref<HTMLElement>();
  let map: Map;
  onMounted(() => {
    map = new mapboxgl.Map({
      container: mapContainer.value,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [2, 46],
      zoom: 4,
    });

    map.on('load', () => {
      addMarkers(events, map);
    });
    console.log(events);
  });

  onBeforeUnmount(() => {
    map.remove();
    map = null;
  });

  function addMarkers(events: Event[], map: Map): void {
    events.forEach(event => {
      const marker = new Marker();
      const popup = new Popup().setHTML(`
        <h3 class="heading-3 text-theme-dark mb-4" style="line-height: 1.2em">${event.title}</h3>
        <p class="mb-4">${event.description}</p>
        ${event.url
          ? `<a href="${event.url}" class="button button--primary" style="font-size: .75rem !important;">Lien vers l’événement</a>`
          : ''
        }

      `);

      marker.setLngLat(new LngLat(...event.coordinates));
      marker.setPopup(popup);
      marker.addTo(map);
    });
  }
</script>

<template>
  <div class="mapbox-map">
    <slot />

    <div class="relative w-full bg-[#75CFF0] h-[380px] md:h-[530px] md:rounded-lg">
      <div ref="mapContainer" class="w-full h-full rounded-3xl">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .mapboxgl {
    &-canvas {
      @apply md:rounded-lg;
    }

    &-popup {
      &-content {
        h1 {
          @apply text-theme-dark text-24 mb-6;
        }

        p {
          @apply text-16 mb-6;
        }
      }

      &-close-button {
        @apply text-16 p-2 hover:ring-2 hover:ring-inset focus:ring-2 focus:ring-inset;
      }
    }
  }
</style>
