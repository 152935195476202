<script lang="ts" setup>
  import { onBeforeUnmount, onMounted, ref } from 'vue';

  const props = defineProps<{
    finalValue: number;
    duration: number;
  }>();

  const value = ref<number>(0);

  const root = ref<HTMLDivElement|null>(null);
  const observer = new IntersectionObserver((e) => {
    if (e[0].isIntersecting) {
      animate();
      observer.unobserve(root.value as HTMLDivElement);
    }
  });
  onMounted((): void => {
    observer.observe(root.value as HTMLDivElement);
  });

  onBeforeUnmount((): void => {
    observer.observe(root.value as HTMLDivElement);
  });

  function animate(): void {
    const startTime = performance.now();
    const startValue = 0;
    const totalChange = props.finalValue - startValue;

    function update(): void {
      const currentTime = performance.now();
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / props.duration, 1);
      const currentValue = startValue + totalChange * progress;

      value.value = Math.round(currentValue);

      if (progress < 1) {
        requestAnimationFrame(update);
      }
    }

    requestAnimationFrame(update);
  }
</script>

<template>
  <div ref="root">
    <slot :value="value" name="figure" />
    <slot name="text" />
  </div>
</template>

<style lang="scss" scoped></style>
