<script setup lang="ts">
  const csrfConfig: {
    csrfTokenName: string;
    csrfTokenValue: string;
  } = window.Craft;
</script>

<template>
  <input
    type="hidden"
    :name="csrfConfig.csrfTokenName"
    :value="csrfConfig.csrfTokenValue"
  />
</template>
